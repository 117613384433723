body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: "Montserrat", "Roboto","Helvetica","Arial",sans-serif !important;
}

/* body{
  background-color: #1d1d1d !important;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.regForm{
  margin: auto;
  margin-top: 12rem;
}
/* .textInput > div > input{
  padding: 10px !important;
  width: 200px !important;
} */

.regSnack{
 margin-top: 15px;
 position: fixed !important;
 left: auto !important;
 top: 55px !important; 
 right: 24px !important; 
 bottom: unset !important; 
}

.submitBtn{
  font-family: 'Roboto' !important;
  font-size:  1rem !important;
}
.qrContainer{
  margin-top: 10px;
  height: 300px;
}
.card{
  margin: 5rem auto;
  padding: 10px;
  border-radius: 3% 3% 3% 3% / 3% 3% 3% 3%;
  border: 1px solid #c9c4c4;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/* .qrContainer > div, .qrContainer>div >video{
  height: 67% !important;
} */

.qrResult{
  margin-top: 100px;
  font-size: 2rem;
}



@media screen and  (max-width:425px){
  .card{
    width: 95%;
  }

  .regSnack{
    top: 24px !important;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

